var Vue = require('vue');

Vue.component('load-more', {
	data: function(){
		return{
			next: 2,
			loading: false
		}
	},
	props:['total','url'],
	methods: {
		stringToHTML(str) {
			let dom = document.createElement('div');
			dom.innerHTML = str;
			return dom;
		},
		loadMore(){
			let target= ((this.url && this.url.length > 0) ? this.url : window.location.pathname);
			if(!target.endsWith('/'))
				target += '/'
			target += 'page/' + this.next + '/' + window.location.search;
			this.loading= true;

			this.$http.get(target).then(response => {
				this.loading = false;
				this.next++
				if(response.body.search('id="trending"') !== -1 || response.body.search('id="recent"') !== -1){
					let html =  this.stringToHTML(response.body);

					let trending = html.querySelector('#trending').innerHTML;

					if(trending)
						document.querySelector('#trending').insertAdjacentHTML('beforeend',trending);
					else
						document.querySelector('#trending + button').style.display = "none"

					let recent = html.querySelector('#recent').innerHTML;

					if(recent)
						document.querySelector('#recent').insertAdjacentHTML('beforeend',recent);
					else
						document.querySelector('#recent + button').style.display = "none"
				} else {
					document.querySelector('#posts').insertAdjacentHTML('beforeend',response.body)
				}

			}, response => {
				alert(response.body.message);
				this.sending = false;
			});
		}
	}
});